import { environment } from "../../environments/environment";
import { HttpParams, HttpHeaders } from "@angular/common/http";
import { HeaderTokenEnum } from "../model/auth.model";

export const apiHost = environment.endpoint;
export const apiPrefix = '';

/**
 * @description Returns a URL generator for building urls from the supplied list of params
 * @param {string} host The host the generator should target
 * @param {string} prefix Any static prefix the generator should include (eg /v1/)
 * @returns {() => string}
 */
export const createAppUrl = (host: string, prefix: string) => (...params: Array<string | number>): string =>
  `${host}${prefix.length ? prefix + '/' : ''}/${params.join('/')}`;

/**
 * @description Returns a generated Url
 * @param {Array<string|number>} params path elements of the desired url
 * @returns {string}
 * @example createUrl("foo","bar"); -> Returns https://host/prefix/foo/bar
 */
export const createUrl = createAppUrl(apiHost, apiPrefix);

/**
 * @description Alias method for generating a blank httpParams object
 * @returns {HttpParams}
 */
export const httpParams = () => new HttpParams();



/**
 * @description Generates http headers specifically indicating that the application does not wish to attempt any validation checks server-side
 * @returns {HttpHeaders}
 */
export const getPublicRoutesHeaders = () => {
  const headers = new HttpHeaders();

  return headers.append('tokenType', HeaderTokenEnum.NoToken);
};