import { environment } from '../../environments/environment';
import * as Sentry from "@sentry/browser";

const className = "LoggerUtil";

/**
 * @description Rudimentary logger
 */

const logLevels = {
  none: 0,
  silly: 1,
  debug: 2,
  info: 3,
  warn: 4,
  error: 5,
  all: 5
} as const;

const logLevelName = (environment.logLevel || "debug") as keyof typeof logLevels;
const logLevel = logLevels[logLevelName] || logLevels.debug;

export namespace logger {

  /**
   * @description Sets the user that the current logs belong to when transmitting those logs externally
   * @param {string|null|undefined} email
   * @returns {void}
   */
  export function setLogUser(email?: string | null | undefined): void {
    const signature = className + ".setLogUser: ";
    if (!!email) {
      Sentry.setUser({ email });
      debug(signature, `Set Log User[${email}]`);
    } else {
      Sentry.configureScope(scope => scope.setUser(null));
      debug(signature, `Set Log User[null]`);
    }
  }

  /**
   * @description Emits single-string messages at the the requested level
   * @param {keyof typeof logLevels} level The level the log message should be emitted at (used for squelching via config)
   * @param {string} signature The source of the log entry (should consist of classname)
   * @param messages 
   */
  export function emitLog(level: keyof typeof logLevels, signature: string, ...messages: unknown[]) {
    if (logLevel <= logLevels[level]) {
      console.log(`${level}: ${signature.trim()} ${messages.map(message => JSON.stringify(message).replace(/[(^")|("$)]/g, '')).join(" - ")}`);
    }
  }

  export function silly(signature: string, ...messages: unknown[]) {
    emitLog('silly', signature, ...messages);
  }

  export function debug(signature: string, ...messages: unknown[]) {
    emitLog('debug', signature, ...messages);
  }

  export function info(signature: string, ...messages: unknown[]) {
    emitLog('info', signature, ...messages);
  }

  export function warn(signature: string, ...messages: unknown[]) {
    emitLog('warn', signature, ...messages);
  }

  export function error(signature: string, ...messages: unknown[]) {
    emitLog('error', signature, ...messages);
  }
}

logger.info(`Initalized Log at ${logLevelName} : ${logLevel}`);