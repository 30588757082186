export enum HeaderTokenEnum {
  NoToken = 'NoToken',
  Bearder = "Bearer"
}

export enum AuthStorageKey {
  jwt = 'jwt',
  jwt_refresh = 'jwt_refresh',
  jwt_token_type = 'jwt_token_type',
}

export interface IJWTPayload {
  accessToken: string;
  refreshToken: string;
  tokenType: "Bearer"
}

export interface IJWTPayloadDecoded {
  userType: "USER" | "ADMIN";
  user: {
    id: number;
    email: string;
    rolePermission: {
      read: boolean;
      write: boolean;
      edit: boolean;
      delete: boolean;
      "module.name": string;
    }[]
  },
  iat: number;
  exp: number;
}