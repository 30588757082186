import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { createUrl, getPublicRoutesHeaders } from '../util/api.util';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { SecureService } from './secure.service';
import { IJWTPayload } from '../model/auth.model';
import { CurrentUser } from '../model/user.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { JwtService } from './jwt.service';
import { DataPayload } from '../model/api.model';
import { logger } from '../util/logger.util';
import { hasKey } from '../util/object.util';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserAuthService {

  public currentUserSubject: BehaviorSubject<CurrentUser | null> = new BehaviorSubject<CurrentUser | null>(null);
  public currentUser: Observable<CurrentUser | null>;

  constructor(
    private __http: HttpClient,
    private __secureService: SecureService,
    private __jwtService: JwtService,
    private __router: Router
  ) {
    this.currentUser = this.currentUserSubject.asObservable();
  }

  /**
   * userRegister
   */
  public getCurrentUser(): Observable<DataPayload<CurrentUser>> {
    return this.__http.get<DataPayload<CurrentUser>>(createUrl('user_auth'))
      .pipe(
        tap(result => {
          if (result.success) {
            this.currentUserSubject.next(result.data);
          } else {
            this.currentUserSubject.next(null);
          }
        }),
        catchError(err => {
          // console.log(err);
          // console.log(err["statusCode"]);
          // console.log("TODO: ONLY LOGOUT ON 403");
          this.__jwtService.removeJWTData();

          throw (err);
        })
      );
  }

  /**
   * Clears all known JWT data and the current user
   */
  public logout() {
    this.__jwtService.removeJWTData();
    this.currentUserSubject.next(null);
    this.__router.navigate(['/user/login']);
  }

  /**
   * login
   */
  public login(credentials: {
    loginEmail: string,
    loginPassword: string
  }) {
    return this.__http.post<IJWTPayload | { loginChallenge: true }>(createUrl('user_auth', 'login'), credentials, {
      headers: getPublicRoutesHeaders()
    })
      .pipe(
        switchMap((payload) => {
          if (hasKey(payload, 'loginChallenge')) {
            return of(payload);
          }

          return this.__secureService.persistJwtPayload(payload as IJWTPayload)
        })
      );
  }

  public verify(credentials: {
    email: string,
    token: string
  }) {
    return this.__http.post<IJWTPayload>(createUrl('user_auth', 'verify'), credentials, {
      headers: getPublicRoutesHeaders()
    });
  }

  /**
   * @todo Move to UserGuardian service
   */
  public verifyGuardian(credentials: {
    email: string,
    token: string
  }) {
    return this.__http.post<IJWTPayload>(createUrl('user_guardian', 'verify'), credentials, {
      headers: getPublicRoutesHeaders()
    });
  }

  /**
   * @description Marks the application owned by the current user as completed
   * @returns 
   */
  public markApplicationComplete(applicant_id: number) {
    return this.__http.get<{ success: boolean }>(createUrl('user_auth', 'complete'), { params: { applicant_id } });
  }

  public register(credentials: Record<string, unknown>) {
    return this.__http.post(createUrl('user_auth', 'register'), credentials, {
      headers: getPublicRoutesHeaders()
    });
  }

  public refreshToken(jwtString: string, jwtRefreshString: string) {
    return this.__http.post<IJWTPayload>(createUrl('user_auth', 'refresh'), {
      accessToken: jwtString,
      refreshToken: jwtRefreshString
    },
      { headers: getPublicRoutesHeaders() }
    );
  }

  public reset(credentials: Record<string, unknown>) {
    return this.__http.post<IJWTPayload>(createUrl('user_auth', 'reset'), credentials, {
      headers: getPublicRoutesHeaders()
    })
      .pipe(
        switchMap((payload) => {
          return this.__secureService.persistJwtPayload(payload)
        })
      );
  }

  public forgot(credentials: Record<string, unknown>) {
    return this.__http.post(createUrl('user_auth', 'forgot'), credentials, {
      headers: getPublicRoutesHeaders()
    });
  }
}
