import { Component } from '@angular/core';

@Component({
    moduleId: module.id,
    selector: 'disabled-application',
    templateUrl: 'disabled-application.component.html',
    styleUrls: ['disabled-application.component.scss']
})
export class DisabledApplicationComponent {

}
