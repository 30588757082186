/**
 * Note: This is a combination example of an AuthService and a secure endpoint service to demonstrate the HTTP Interceptors for JWT. Consider
 * breaking this into multiple services
 */

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, throwError } from "rxjs";
import { switchMap } from "rxjs/operators";
import { IJWTPayload, IJWTPayloadDecoded } from "../model/auth.model";
import { createUrl, getPublicRoutesHeaders } from "../util/api.util";
import { JwtService } from "./jwt.service";

@Injectable({
  providedIn: 'root'
})
export class SecureService {
  constructor(
    public httpClient: HttpClient,
    public __jwtService: JwtService
  ) { }

  /**
   * @description Fetches some secured data for testing
   */
  public readonly fetch = () => {
    return this.httpClient.get<IJWTPayload>(createUrl('auth', 'getData'));
  }

  /**
   * @description Fetches some secured data without authenticating
   */
  public readonly fetchImproperly = () => {
    return this.httpClient.get<IJWTPayload>(createUrl('auth', 'getData'), {
      headers: getPublicRoutesHeaders()
    });
  }

  /**
   * @description Fetches an example JWT Token for handling
   */
  public readonly authenticate = (username: string, password: string) => {
    return this.httpClient.post<IJWTPayload>(createUrl('auth', 'authenticate'),
      { username, password },
      { headers: getPublicRoutesHeaders() }
    ).pipe(
      switchMap(this.persistJwtPayload.bind(this))
    )
  }

  /**
   * @description Fetches a new JWT token using the supplied refresh token
   */
  public readonly refreshToken = (jwtString: string, jwtRefreshString: string): Observable<IJWTPayload> => {
    return this.httpClient.post<IJWTPayload>(
      createUrl('auth', 'refresh'),
      {
        accessToken: jwtString,
        refreshToken: jwtRefreshString
      },
      { headers: getPublicRoutesHeaders() }
    );
  }

  /**
   * @description Convenience method. Can be used throughout the application to identify whether or not the user is currently authentiated
   * @returns {boolean}
   */
  public readonly isAuthenticated = (userType: IJWTPayloadDecoded["userType"]): boolean => {
    const jwt = this.__jwtService.decodeJWT();

    if (jwt && this.__jwtService.verifyJWT(jwt) && jwt.userType === userType) {
      return true
    }

    return false;
  }

  /**
   * @description Attempts to persist the supplied JWT payload and validate and validate the users authentication status
   * @param payload 
   * @returns 
   */
  public persistJwtPayload(payload: IJWTPayload): Observable<IJWTPayload> {
    if (!(this.__jwtService.saveJWTData(payload)) || !this.__jwtService.verifyJWT()) {
      this.__jwtService.removeJWTData();

      return throwError("Error Saving JWT Payload");
    }

    return of(payload);
  }
}