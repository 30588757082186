import { FormGroup, ValidationErrors } from "@angular/forms";
import { NotificationsService } from "angular2-notifications";
import { logger } from "./logger.util";

const className = "FormUtil";

export type FormValidationErrorSummary = { [x: string]: ValidationErrors; }

/**
 * @interface ValidateFormInput
 * @property {FormGroup} form The form group to evaluate
 * @property {FormGroup | null} notificationsService The notification service responsible for dispatching messages to the user. Alert is used if this is not passed
 * @property {FormGroup | null} errorMessage The string message to emit in the exception that is thrown when errors are found
 * @property {string[] | null} ignoreFields An array of strings indicating which form keys should be ignored
 * @property {( () => void ) | null} onError Lambda to execute if the form contains errors
 */
type ValidateFormInput = {
  form: FormGroup;
  notificationsService?: NotificationsService;
  errorMessage?: string;
  ignoreFields?: string[] | null;
  onError?: (() => void) | null;
  buildMessage?: ((errors: FormValidationErrorSummary) => string | void | null | undefined) | null;
};

/**
 * @description Fetches an array of all errors in a FormGroup
 * @param {FormGroup} group 
 * @returns {Record<string, unknown>}
 */
const getAllFormErrors = (group: FormGroup, ignoreFields?: string[] | null): FormValidationErrorSummary => {

  const result = {} as FormValidationErrorSummary;

  Object.keys(group.controls)
    .filter(key => !!ignoreFields ? !ignoreFields.includes(key) : true)
    .map(key => ({ key, errors: group.controls[key].errors }))
    .filter(data => !!data.errors)
    .forEach(data => {
      result[data.key] = data.errors!
    });

  return result;
};

/**
 * @description Creates a pretty, comma delimited representation of errors detected in a form and the name of the validator that failed
 * @param {FormGroup} group 
 * @returns {string}
 */
const prettyFormErrors = (groupOrSummary: FormGroup | FormValidationErrorSummary): string => {
  const errors = groupOrSummary instanceof FormGroup ? getAllFormErrors(groupOrSummary) : groupOrSummary;

  return Object.keys(errors)
    .map(key => `Field[${key}] Failed[${Object.keys(errors[key]).join(",")}]`)
    .join(", ");
}

/**
 * @description Provides a consistent way to write form errors to logs
 * @param {FormGroup | FormValidationErrorSummar} group 
 */
const logFormErrors = (groupOrSummary: FormGroup | FormValidationErrorSummary): void => {
  const signature = className + ".logFormErrors:";
  logger.info(signature, "Validation Error", prettyFormErrors(groupOrSummary));
}

/**
 * @description Provides a consistent way to display error notifications for failed form validations. Assumes errors exist on the form
 * @param {FormValidationErrorSummary} summary The errors which were detected in any form group
 * @param {NotificationsService | undefined} notificationsService The notification service responsible for replacing the alert function
 * @param {string | undefined} notificationText Override for the error message
 * @param {string | undefined} notificationTitle Override for the error title - Unused when notificationsService is null
 */
const notifyFormErrors = (opts: {
  summary: FormValidationErrorSummary,
  notificationsService?: NotificationsService | null,
  notificationText?: string | null,
  notificationTitle?: string | null
}): void => {
  const message = opts.notificationText || `Invalid Fields: ${Object.keys(opts.summary).join(", ")}`;
  const title = opts.notificationTitle || "Invalid Data";
  if (opts.notificationsService) {
    opts.notificationsService.error(title, message);
  } else {
    // Defer this so that the exception can be thrown
    setTimeout(() => { alert(message); }, 0);
  }
}

/**
 * @description Provides a consistent way of handling form validation throughout the application. Returns void. An error is thrown if errors are found
 * @param {ValidateFormInput} opts FormValidate options.
 */
export const validateForm = (opts: ValidateFormInput): void => {
  if (!opts.form.valid) {
    const errors = getAllFormErrors(opts.form, opts.ignoreFields);
    if (Object.keys(errors).length === 0) return;

    let message = 'Please fill all required fields';

    if (opts.buildMessage) {
      message = opts.buildMessage(errors) || message;
    }

    logFormErrors(errors);
    notifyFormErrors({
      summary: errors,
      notificationsService: opts.notificationsService,
      notificationText: opts.notificationsService ? null : message
    });

    if (opts.onError) {
      opts.onError();
    }

    const error = new Error(opts.errorMessage || "Form Validation Error");
    throw error;
  }
}