<!--component html goes here -->
<app-header></app-header>

<div class="app-body">
  <main class="main">
    <div class="container-fluid">
      <div class="header-top">

        <section class="create-profile">
          <div class="container">
            <div class="row cps cps-4 formBlock">
              <div class="col-lg-7 cp-box mx-auto">
                <form>
                  <div class="mb-3">
                    <label>&nbsp;</label>
                    <label class="form-label text-center d-block">Youfor2032 is currently unavailable.</label>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>

      </div>
    </div>
  </main>
</div>
