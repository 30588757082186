<section>
  <header>
    <nav class="navbar my-navbar navbar-expand-lg navbar-dark d-flex align-items-center">
      <div class="container-fluid px-5">
        <a class="navbar-brand" href="#">
          <img src="../../../assets/images/logo.png" class="img-fluid" alt="">
        </a>
        <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
          aria-label="Toggle navigation">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <div class="vector-container d-lg-none">
            <img role="presentation" alt="" data-src="../../../assets/images/down-chevron.svg"
              class="w-[80px] mx-auto lazyloaded" src="../../../assets/images/down-chevron.svg">

            <div class="ms-auto d-lg-none mt-4 pt-0 subtitle">Queensland Academy of Sport</div>
          </div>

          <div class="navbar-wrapper">
            <ul class="navbar-nav mx-auto mb-2 mb-lg-0 d-flex align-items-center">
              <li class="nav-item">
                <a class="nav-link" href="https://youfor2032.initiatives.qld.gov.au/">Home</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://youfor2032.initiatives.qld.gov.au/the-path-to-2032">The path to
                  2032</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="https://youfor2032.initiatives.qld.gov.au/about">About</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" *ngIf="isAuthenticated" [ngClass]="isActiveTab=='apply' ? 'active' : ''"
                  [routerLink]="'/'" [queryParams]="{ newApplication: true }">Apply</a>

                <a class="nav-link" *ngIf="!isAuthenticated" [ngClass]="isActiveTab=='apply' ? 'active' : ''"
                  [routerLink]="'/'">Apply</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/user','login']" [ngClass]="isActiveTab=='login' ? 'active' : ''"
                  *ngIf="!isAuthenticated">Login</a>
                <a class="nav-link" (click)="logout()" *ngIf="isAuthenticated">Logout</a>
              </li>
            </ul>

            <p class="text-center d-lg-none"><img src="../../assets/images/logo.png"
                class="img-fluid logo-mobile d-inline-block" alt="">
            </p>
          </div>
          <div class="ms-auto d-none d-lg-block">
            <h4>Queensland Academy of Sport</h4>
          </div>

        </div>

      </div>
    </nav>
  </header>
  <div class="header-bottom">
    <h2>{{(s.language.campaign$ | async)?.singular}} APPLICATION</h2>
  </div>
</section>