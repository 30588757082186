import { FormGroup } from '@angular/forms';



export function ConfirmedValidator(controlName: string, matchingControlName: string, caseSensitive: boolean = true) {

  return (formGroup: FormGroup) => {

    const control = formGroup.controls[controlName];

    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors['confirmedValidator']) {

      return;

    }

    if (
      (caseSensitive && control.value !== matchingControl.value)
      || (!caseSensitive && control.value.toLowerCase() !== matchingControl.value.toLowerCase())
    ) {

      matchingControl.setErrors({ confirmedValidator: true });

    } else {

      matchingControl.setErrors(null);

    }

  }

}