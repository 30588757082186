import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { CreateProfileComponent } from './create-profile/create-profile.component';
import { MindsetComponent } from './mindset/mindset.component';
import { BackgroundComponent } from './background/background.component';
import { IntrestsComponent } from './intrests/intrests.component';
import { SportRatingComponent } from './sport-rating/sport-rating.component';
import { FinishLineComponent } from './finish-line/finish-line.component';
import { ApplicationStatusComponent } from './application-status/application-status.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { UserLoginComponent } from './user-login/user-login.component';
import { UserVerifyComponent } from './user-verify/user-verify.component';
import { GuardianVerifyComponent } from './guardian-verify/guardian-verify.component';
import { AuthGuard } from './guard/auth.guard';
import { DisabledApplicationComponent } from './admin/views/disabled-application/disabled-application.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: DefaultLayoutComponent,
    data: {
      title: 'Home'
    },
    children: [
      {
        path: '',
        redirectTo: 'create_profile',
        pathMatch: "full",
      },
      {
        path: 'create_profile',
        component: CreateProfileComponent,
        data: {
          title: 'Create Profile'
        },
      },
      {
        path: 'new_application',
        component: CreateProfileComponent,
        data: {
          title: 'Create Profile'
        },
      },
      {
        path: 'mindset',
        component: MindsetComponent,
        data: {
          title: 'Mindset'
        },
      },
      {
        path: 'background',
        component: BackgroundComponent,
        data: {
          title: 'Background'
        },
      },
      {
        path: 'intrests',
        component: IntrestsComponent,
        data: {
          title: 'intrests'
        },
      },
      {
        path: 'sport-rating',
        component: SportRatingComponent,
        data: {
          title: 'sport-rating'
        },
      },
      {
        path: 'finish-line',
        component: FinishLineComponent,
        data: {
          title: 'finish-line'
        },
      },
    ]
  },
  {
    path: 'user',
    component: LoginLayoutComponent,
    data: {
      title: 'User'
    },
    children: [
      {
        path: '',
        redirectTo: 'application-status',
        pathMatch: "full",
      },
      {
        path: 'reset/:token',
        component: UserLoginComponent,
        data: {
          title: 'Login',
          resetBlock: true
        },
      },
      {
        path: 'login',
        component: UserLoginComponent,
        data: {
          title: 'Login'
        },
      },
      {
        path: 'verify',
        component: UserVerifyComponent,
        data: {
          title: 'Verify'
        },
      },
      {
        path: 'verify-guardian',
        component: GuardianVerifyComponent,
        data: {
          title: 'Verify Guardian'
        },
      },
      {
        path: 'application-status',
        component: ApplicationStatusComponent,
        canActivate: [AuthGuard],
        data: {
          title: 'application-status'
        },
      },
    ]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  }
  // { path: '**', component: NotFoundComponent }
];


export const disabledRoutes: Routes = [
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: '**',
    component: DisabledApplicationComponent
  }
];


@NgModule({
  imports: [RouterModule.forRoot(disabledRoutes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
