import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { environment } from "../../../src/environments/environment";
import { HeaderTokenEnum, IJWTPayload } from "../model/auth.model";
import { JwtService } from "../service/jwt.service";
import { SecureService } from "../service/secure.service";
import { UserAuthService } from "../service/user-auth.service";
import { logger } from "../util/logger.util";

const className = "JwtInterceptor";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private readonly jwtService: JwtService,
    private readonly secureService: SecureService,
    private readonly userAuthService: UserAuthService,
    private readonly router: Router,
  ) {
  }

  intercept<T = unknown>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> {
    const signature = className + `.intercept: Method[${request.method}] to Url[${request.url}] `;

    logger.silly(signature, "Intercepting HTTPRequest");

    /** Do not attach the authentication token unless we are looking at our own api */
    if (request.url.indexOf(environment.endpoint) !== 0) {
      logger.silly(signature, "Found external Endpoint. Authorization not required.");
      return next.handle(request);
    }

    if (request.headers.get("tokenType") === HeaderTokenEnum.NoToken) {
      logger.silly(signature, "Found public headers. Authorization not required.");
      return next.handle(request);
    }

    logger.silly(signature, "Adding Authorization Data");

    const payload = this.jwtService.decodeJWT();

    if (!payload) {
      logger.debug(signature, "JWT Payload is blank");
      return this.onAuthorizationFailed("Authorization keys were not found on endpoint expecting authorization");
    }

    // TODO: Remove this to stop simulating an expired payload
    const simulateExpired: boolean = false;

    if (this.jwtService.isExpired(payload) || simulateExpired) {

      if (payload.userType === "USER") {
        // Perform the Token refresh
        return this.userAuthService.refreshToken(
          this.jwtService.getJWTString(),
          this.jwtService.getJWTRefreshString()
        ).pipe(
          switchMap(payload => {
            return this.onRefresh(payload, request, next);
          }),
          catchError(err => {
            this.onAuthorizationFailed("Error Refreshing Token");
            return throwError(err);
          })
        );
      } else {
        // Perform the Token refresh
        return this.secureService.refreshToken(
          this.jwtService.getJWTString(),
          this.jwtService.getJWTRefreshString()
        ).pipe(
          switchMap(payload => {
            return this.onRefresh(payload, request, next);
          }),
          catchError(err => {
            this.onAuthorizationFailed("Error Refreshing Token");
            return throwError(err);
          })
        );
      }
    }

    // JWT should now be known to exist and be not expired, but double check it
    if (!this.jwtService.verifyJWT(payload)) {
      logger.debug(signature, "JWT is invalid");
      return this.onAuthorizationFailed("Invalid authorization keys were found");
    }

    const modifiedRequest = this.setAuthorizationRequest(request);

    if (modifiedRequest) {
      logger.silly(signature, "Successfully attached Authorization");
      return next.handle(modifiedRequest) as Observable<HttpEvent<T>>;
    } else {
      return this.onAuthorizationFailed("Unable to insert authorization into request");
    }
  }

  /**
   * @description What should be done if the authentication cannot be attached. This should not return any value as it is intended to handle flow rather than
   */
  private readonly onAuthorizationFailed = (error: unknown | null = null): Observable<never> => {
    const signature = className + ".onAuthorizationFailed: ";

    this.router.navigate(["/user/login"]);

    return throwError(error);
  }

  /**
   * @description Inline handler for refreshing a token and immediately using it
   * @param {IJWTPayload} jwtPayload 
   * @returns {Observable<null> | Observable<HttpEvent<T>>}
   */
  private readonly onRefresh = <T = unknown>(payload: IJWTPayload, request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<T>> => {
    if (!(this.jwtService.saveJWTData(payload)) || !this.jwtService.verifyJWT()) {
      this.jwtService.removeJWTData();

      return throwError("Error Saving JWT Payload");
    }

    const repeatModifiedRequest = this.setAuthorizationRequest(request) as typeof request;

    if (repeatModifiedRequest) {
      return next.handle(repeatModifiedRequest) as Observable<HttpEvent<T>>;
    } else {
      return this.onAuthorizationFailed("Unable to insert authorization into request");
    }
  }

  /**
   * If the last known JWT token is valid, attach the authorization headers and return the prepared request object
   *
   * @param {HttpRequest<any>} request
   * @returns {HttpRequest<any>|false}
   */
  private readonly setAuthorizationRequest = <T = unknown>(request: HttpRequest<T>): HttpRequest<T> | null => {
    const validJWT = this.jwtService.verifyJWT();

    if (validJWT) {
      request = request.clone({
        setHeaders: {
          Authorization: `${this.jwtService.getJWTTypeString()} ${this.jwtService.getJWTString()}`
        }
      });

      return request;
    }

    return null;
  };
}
