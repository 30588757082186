import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { map, switchMap, tap } from "rxjs/operators";
import { logger } from "../util/logger.util";
import { UserAuthService } from "./user-auth.service";

const className = "SessionService";

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  constructor(
    private __userAuthService: UserAuthService,
  ) { }

  // When not null, currentApplication$ will attempt to find this application
  private _selectedApplicationId: number | null = null;

  /**
   * @description Fetches the current, or default application from the current user
   */
  public currentApplication$ = this.__userAuthService.currentUserSubject.pipe(
    map(currentUser => {
      if (!currentUser) {
        return null;
      }

      if (!currentUser.applications || !currentUser.applications.length) {
        return null;
      }

      if (this._selectedApplicationId) {
        const selectedApplication = currentUser.applications.find(application => application.id === this._selectedApplicationId);

        if (selectedApplication) {
          return selectedApplication;
        }

        this._selectedApplicationId = null;
      }

      const mostRecentApplication = currentUser.applications.sort((a, b) => a.id > b.id ? -1 : 0)[0];

      return mostRecentApplication;
    })
  );

  /**
   * @description Primes the service to select the target application next time the user data is loaded
   * @param applicationId 
   */
  public setTargetApplication(applicationId: number) {
    this._selectedApplicationId = applicationId;
  }

  /**
   * @description Used to publish/store the game type of the current session
   */
  public gameType$ = this.currentApplication$.pipe(
    map(application => {
      if (!application) {
        return null;
      }

      return application.game_type;
    })
  );

  /**
   * @description Used to provide consistent language replacements in various situations
   * @example (s.language.campaign$ | async)?.styledFullName
   */
  public language = {
    campaign$: this.gameType$.pipe(
      map(gameType => {
        if (gameType && gameType === 'daredevil') {
          return {
            name: "the Daredevil campaign",
            singular: "Daredevil",
            formalName: "The Daredevil Campaign",
            styledShortName: "the <i>Daredevil</i> campaign",
            styledFullName: "the <i>Daredevil</i> campaign"
          };
        }

        return {
          name: "Youfor2032",
          singular: "Youfor2032",
          formalName: "Youfor2032 Talent Identification program",
          styledShortName: "the <i>Youfor2032</i> program",
          styledFullName: "the <i>Youfor2032</i> Talent Identification program"
        };
      })
    )
  };
}