import { Directive, ElementRef, HostListener, Input } from '@angular/core';

const Nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0].map(val => String(val))

@Directive({
  selector: '[numbersOnly]'
})
export class NumbersOnlyDirective {

  constructor(private el: ElementRef) { }

  @HostListener('change', ['$event'])
  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    const target = event.target as HTMLInputElement;
    target.value = target.value.replace(/\D/g, '');
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (!event) {
      return;
    }

    const key = (event.key) || (event.charCode && String.fromCharCode(event.charCode)) || (event.keyCode && String.fromCharCode(event.keyCode)) || '';

    if (
      // Quality of Life keys
      ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab'].includes(key) ||
      // Allow: Ctrl+A
      (key === 'a' && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (key === 'c' && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (key === 'v' && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (key === 'x' && (event.ctrlKey || event.metaKey))) {
      return;
    }

    if (key.length && !Nums.includes(key)) {
      event.preventDefault();
    }

    /**
    if ([, 8, 9, 27, 13, 110, 190].indexOf(e.keyCode) !== -1 ||
      // Allow: Ctrl+A
      (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+C
      (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+V
      (e.keyCode === 86 && (e.ctrlKey || e.metaKey)) ||
      // Allow: Ctrl+X
      (e.keyCode === 88 && (e.ctrlKey || e.metaKey)) ||
      // Allow: home, end, left, right
      (e.keyCode >= 35 && e.keyCode <= 39)) {
        console.log("let it happen why doe?");
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (e.keyCode < 48 || e.keyCode > 57)) && (e.keyCode < 96 || e.keyCode > 105)) {
      console.log("prevent");
      e.preventDefault();
    }
    */
  }
}