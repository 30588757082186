import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SecureService } from '../service/secure.service';
import { UserAuthService } from '../service/user-auth.service';
import { logger } from '../util/logger.util';
import { hasKey } from '../util/object.util';

const className = "UserVerifyComponent";

@Component({
  selector: 'app-verify',
  templateUrl: './guardian-verify.component.html',
  styleUrls: ['./guardian-verify.component.scss']
})
export class GuardianVerifyComponent implements OnInit {

  title: string = 'Guardian Verify';

  public sendingVerificationBlock: boolean = true;
  public errorVerifyingBlock: boolean = false;
  public successVerifyingBlock: boolean = false;

  constructor(
    private __userAuthService: UserAuthService,
    private __activatedRoute: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.validateGuardian();
  }

  validateGuardian() {
    const signature = className + ".validateGuardian";
    this.__activatedRoute.queryParams.subscribe(queryParams => {
      let hasAllValues = true;

      if (!hasKey(queryParams, 'email')) {
        logger.info(signature, `Will fail verification due to missing email in params`);
        hasAllValues = false;
      }

      if (!hasKey(queryParams, 'token')) {
        logger.info(signature, `Will fail verification due to missing token in params`);
        hasAllValues = false;
      }

      const { email, token } = queryParams;

      if (!hasAllValues) {
        this.sendingVerificationBlock = false;
        this.errorVerifyingBlock = true;
        return;
      }

      this.__userAuthService.verifyGuardian({ email, token }).subscribe({
        next: res => {
          if (hasKey(res, 'success') && typeof res.success === 'boolean' && res.success === true) {
            this.sendingVerificationBlock = false;
            this.successVerifyingBlock = true;
            return;
          }

          this.sendingVerificationBlock = false;
          this.errorVerifyingBlock = true;

          alert("There was an error verifying your your consent email. Please refresh or click the link in your email to try again.");
          throw new Error("There was an error verifying guardian data");
        },
        error: err => {
          this.sendingVerificationBlock = false;
          this.errorVerifyingBlock = true;

          alert("There was an error verifying your your consent email. Please refresh or click the link in your email to try again.");
          console.error(JSON.stringify(err));
          throw new Error("There was an error verifying guardian data");
        }
      }
      );
    })
  }
}