import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { logger } from './util/logger.util';

const className = "AppComponent";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Queensland_Government';

  constructor(
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

    this.sortHeader();
  }

  /**
   * @description Inspects the <head> tag of the document and moves css files after "styles.css" if it is appropriate
   */
  sortHeader() {
    const signature = className + ".sortHeader: ";
    const domHeads = document.getElementsByTagName("head");
    if (!domHeads.length) {
      logger.warn(signature, "Could not find a dom head");
      return;
    }

    const domHead = domHeads.item(0) as HTMLHeadElement;

    let idx = 0;
    let nextItem = domHead.children.item(idx);
    while (nextItem) {
      idx++;
      const lastChildAttr = nextItem.getAttribute("aria-last-child");
      if (lastChildAttr && lastChildAttr.toLowerCase() === 'true') {
        logger.silly(signature, `Reorded dom item[${nextItem.getAttribute('id')}]`)
        domHead.append(nextItem);
      }

      if (idx > 1000) {
        logger.warn(signature, "Reached max iterations when inspecting header items")
        break;
      }

      nextItem = domHead.children.item(idx);
    }
  }
}
