import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IgxStepperModule, IgxIconModule, IgxButtonModule, IgxButtonGroupModule, IgxInputGroupModule, IgxRadioModule } from 'igniteui-angular';
import { NotFoundComponent } from './common/not-found/not-found.component';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { CreateProfileComponent } from './create-profile/create-profile.component';
import { CalendarModule } from 'primeng/calendar';
import { MindsetComponent } from './mindset/mindset.component';
import { BackgroundComponent } from './background/background.component';
import { IntrestsComponent } from './intrests/intrests.component';
import { SportRatingComponent } from './sport-rating/sport-rating.component';
import { TableModule } from 'primeng/table';
import { FinishLineComponent } from './finish-line/finish-line.component';
import { ApplicationStatusComponent } from './application-status/application-status.component';
import { FormsModule, FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { DialogModule } from 'primeng/dialog';
import { CookiesStorageService, WebStorageModule } from 'ngx-store';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";
import { NgSelect2Module } from 'ng-select2';


import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { UserLoginComponent } from './user-login/user-login.component';
import { SecureService } from './service/secure.service';
import { AuthService } from './service/auth.service';
import { JwtService } from './service/jwt.service';
import { UserAuthService } from './service/user-auth.service';
import { JwtInterceptor } from './interceptor/jwt.interceptor';
import { UserVerifyComponent } from './user-verify/user-verify.component';
import { GuardianVerifyComponent } from './guardian-verify/guardian-verify.component';
import { Router } from '@angular/router';
import { InputMaskModule } from '@ngneat/input-mask';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { HealthQuestionaireComponent } from './health-questionaire/health-questionaire.component';
import { environment } from 'src/environments/environment';
import { VideoUploadComponent } from './video-upload/video-upload.component';
import { GuardianDetailComponent } from './guardian-detail/guardian-detail.component';
import { HealthQuizComponent } from './health-quiz/health-quiz.component';
import { ErrorInterceptor } from './interceptor/error.interceptor';
import { NumbersOnlyDirective } from './directive/numbersOnly.directive';
import { DebounceClickDirective } from './directive/debouceClick.directive';
import { MediaTakingComponent } from './media-taking/media-taking.component';
import { ConfirmDetailsComponent } from './confirm-details/confirm-details.component';
import { BackgroundSportComponent } from './background/background-sport/background-sport.component';
import { DisabledApplicationComponent } from './admin/views/disabled-application/disabled-application.component';

declare const BUILD_ID: string;

Sentry.init({
  dsn: "https://69c00e3ba5ec4cf98c8b58933a5457c4@o135869.ingest.sentry.io/6307522",
  environment: environment.production ? 'Production' : 'Development',
  release: BUILD_ID,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    NotFoundComponent,
    DefaultLayoutComponent,
    DisabledApplicationComponent,
    CreateProfileComponent,
    MindsetComponent,
    BackgroundComponent,
    BackgroundSportComponent,
    IntrestsComponent,
    SportRatingComponent,
    FinishLineComponent,
    ApplicationStatusComponent,
    VideoUploadComponent,
    LoginLayoutComponent,
    UserLoginComponent,
    UserVerifyComponent,
    GuardianVerifyComponent,
    PrivacyPolicyComponent,
    HealthQuestionaireComponent,
    GuardianDetailComponent,
    HealthQuizComponent,

    NumbersOnlyDirective,
    DebounceClickDirective,
    MediaTakingComponent,
    ConfirmDetailsComponent,
  ],
  imports: [
    NgSelect2Module,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    IgxStepperModule,
    IgxButtonModule,
    IgxButtonGroupModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    DialogModule,
    IgxIconModule,
    IgxInputGroupModule,
    IgxRadioModule,
    InputMaskModule,
    HttpClientModule,
    PasswordStrengthMeterModule,
    WebStorageModule.forRoot(),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
    CookiesStorageService,
    SecureService,
    AuthService,
    UserAuthService,
    JwtService,
    FormBuilder,
    Title
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
