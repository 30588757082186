import { Component, OnInit } from '@angular/core';
import { SessionService } from '../service/session.service';
import { UserAuthService } from '../service/user-auth.service';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isAuthenticated: boolean = false;
  isActiveTab: string = 'apply';

  constructor(
    private __userAuthService: UserAuthService,
    private __session: SessionService,
    private __titleService: Title,
    private __router: Router,
  ) {
    this.__session.language.campaign$.subscribe((itm) => {
      this.__titleService.setTitle(itm.singular);
    })
  }

  // convenience getter for easy access to session data
  get s() { return this.__session; }

  ngOnInit(): void {
    this.__router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe({
      next: () => {
        this.setActiveTab();
      }
    });

    this.__userAuthService.currentUser.subscribe(user => {
      this.isAuthenticated = !!user;
    });

    this.setActiveTab();
  }

  setActiveTab() {
    switch (this.__router.url) {
      case '/user/login':
        this.isActiveTab = 'login';
        break;

      default:
        this.isActiveTab = 'apply';
        break;
    }
  }

  logout(): void {
    this.__userAuthService.logout();
  }
}
